<template>
    <section>
        <div class="loader" v-if="preload">
            <Loader/>
        </div>
        <section id="investors" v-else>
            <div class="header-img row justify-content-center">
                <!-- <img id="ibancarLogo" class="w-100" src="@/assets/img/bg_investor.webp" alt="Ibancar"> -->
                <div class="bg-footer row justify-content-center">
                    <div class="d-flex align-items-center text-center">
                        <h1 class="title-header"><strong><b style="color:#ffa500;">Investor</b> Presentation 2021</strong></h1>
                    </div>
                </div>
            </div>
            <div class="bg-footer row justify-content-center">
                <div class="d-flex align-items-center text-center">
                    <h2 class="title container-fluid">Funding the next phase.</h2>
                </div>
            </div>
            <div class="relleno"></div>
            <div class="row justify-content-center pt-3">
                <div class="d-flex align-items-center text-center">
                    <!-- Mobile -->
                    <!--a href="/pdf/ibancar-investor.pdf#toolbar=0" target="_blank" class="btn d-block d-md-none" style="background-color: rgb(52, 79, 106);color: white;">See corporate presentation</a>
                    < Desktop >
                    <iframe src="/pdf/ibancar-investor.pdf#toolbar=0" frameborder="0" class="d-none d-md-block"></iframe-->
                </div>
            </div>
            <div class="relleno"></div>
        </section>
    </section><!-- /section -->
</template>

<script>
import axios from 'axios'
import Loader from '@/components/Loader.vue'

export default {
    name: 'AvisoLegal',
    data() {
        return {
            preload: true,
        }
    },
    components: {
        Loader,
    },
    methods: {
        setInvestor(){
            // Buscar id solicitud
            var investor = this.$route.query.inv;
            axios.post(process.env.VUE_APP_AXIOS_BASE_URL+'insert/log/investor', {inv: investor, page: 'investor'})
            .then((resp) => {
                if(resp.data){
                   this.preload = false;
                }else{
                   window.location.replace('/home');
                }
            });
        }
    },
    mounted: function(){
        this.setInvestor();
    }
}
</script>

<style scoped lang="scss">
    @import '@/assets/styles/variables.scss';

    .jumbotron {
        padding: 25px 0 0 25px;
        margin-top: 90px;
        background-color: #fff;
    }

    .header-img {
        position: relative;
        text-align: center;
        color: white;
        height: 500px;
        background-image: url("../assets/img/bg_investor.webp");
        background-repeat: round;
        background-size: cover;
    }

    .title-header{
        color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .bg-footer {
        background-size: cover;
        background-color: $blue;
        height: 145px;
        color: $white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    
    iframe{
        width: 60vw;
        height: 80vh;
    }

    .relleno {
        height: 50px;
    }

    .loader{
        height: calc(100vh - 410px);
        display: flex;
        align-items: center;
        justify-content: center;
        background: $blue-2;
    }
  // Resposive para pantallas a partir de tamaño medio a pequeño

@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';

@include media-breakpoint-between(xs,sm) {
    iframe{
        width: 80vw;
        height: 50vh;
    }
}

@include media-breakpoint-between(md,lg) {
    iframe{
        width: 80vw;
        height: 80vh;
    }
}

@include media-breakpoint-between(xl,xxl) {
        width: 80vw;
        height: 80vh;
}
</style>